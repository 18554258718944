@import '../../styles/variables/variables';

.tabs-wrapper {
  justify-content: space-around;
  display: flex;
}

.tabs {
  padding: 15px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: $brand-gray-360;
  background-color: $brand-gray-100;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.active-tabs {
  color: $brand-gray-320;
}

.content {
  background: $white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
  border-radius: 8px;

  .notes {
    display: flex;
    align-items: center;

    input[type=text] {
      height: 57px;
      background: $white;
      border: 1px solid $brand-gray-120;
      width: 85%;
      margin-left: 13px;
      padding: 17px;
      font-size: 18px;
      line-height: 22px;
      color: $brand-gray-360;
      outline: none;
      margin-right: 25px;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }



    & div {
      height: 27px;
      width: 27px;
      margin-right: 10px;
      border-radius: 27px;
    }

    & div active {
      color: $brand-gray-320;
    }
  }


  .comment-notes {
    width: 100%;
  }

  .comment-notes span p {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .first-lamb {
    background-color: #E43D30;

    &--active {
      @extend .first-lamb;
      border: 1px solid black;
      box-shadow: 0 0 5px;
    }
  }

  .second-lamb {
    background-color: #FBC119;
    ;

    &--active {
      @extend .second-lamb;
      border: 1px solid black;
      box-shadow: 0 0 5px;
    }
  }

  .third-lamb {
    background-color: #229342;

    &--active {
      @extend .third-lamb;
      border: 1px solid black;
      box-shadow: 0 0 5px;
    }

  }

  button {
    padding: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $brand-orange;
    background-color: $white;
    right: 0;
    padding-right: 15px;
    cursor: pointer;
  }
}


.comment {
  margin-top: 19px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: flex-start;
  gap: 13px;
  border-bottom: 1px solid rgb(221, 221, 221);
  border-radius: 8px;

  &-red {
    background: rgba(255, 106, 88, 0.1);
  }

  &-yellow {
    background: rgba(255, 219, 125, 0.15);
  }

  &-green {
    background: rgba(99, 194, 103, 0.1);
  }

  >img {
    margin: 0 0 0 16px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }

  &-line {
    width: calc(100% - 66px);
  }

  .data-area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    &-left {
      display: flex;
      flex-direction: column;
      font-weight: 0;
      width: calc(100% - 100px);
      cursor: pointer;

      &-name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #3A3A3C;
      }

      &-text {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #3A3A3C;
      }

      // &-note p {
      //   margin: 0;
      //   width: 100%;
      // }

      // &-name{
      //     white-space: nowrap;
      //     // overflow: hidden;
      // }

      // &-name {
      //   color: grey;
      //   text-overflow: ellipsis;
      //   white-space: nowrap;
      //   overflow: hidden;

      //   span {
      //     right: 0;
      //   }
      // }
    }

    &-right {
      display: flex;
      align-items: center;
      margin-right: 24px;
      margin-left: 12px;
      // min-width: 100px;

      &-edit {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #FFFFFF;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

      }

      &-remove {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #FFFFFF;
        cursor: pointer;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }


    }

  }


  div {
    font-weight: 600;
  }

  span {
    font-weight: normal;
    margin-left: 5px;
  }

  .date {
    font-size: 13px;
    line-height: 16px;
    color: $brand-gray-360;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: -16px;
    cursor: pointer;

    &-text {
      height: 15px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #9E9E9E;
      margin-right: 2px;
    }

    &-datetime {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 3px 4px;
      gap: 10px;
      height: 24px;
      border-radius: 2px;
    }

    &-noteCount {
      height: 24px;
      margin-left: 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.04em;
      color: #9E9E9E;
    }
  }
}

.active-content {
  display: block;
}

.project-name {
  height: 22px;
  font-family: 'Cursive';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
}

.removeButton {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
}


@keyframes highlight {
  20% {
    background-color: rgb(255, 189, 189);
    color: red;
  }

  40% {
    background-color: white;
  }

  60% {
    background-color: rgb(255, 189, 189);
    color: red;
  }

  80% {
    background-color: white;
  }

  100% {
    background-color: rgb(255, 189, 189);
    color: red;
  }
}

.input-required {
  animation-name: highlight;
  animation-duration: .5s;
  /* background-color: rgb(255, 189, 189) !important;
  color: red !important; */
}