@import '../../styles/variables/variables';

.urgent-note-list {
  display: flex;
  border-bottom: 1px solid $brand-gray-120;
  margin: 0 30px;
  padding: 10px 0 10px;
  align-items: center;


  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    word-break: normal;
    cursor: pointer;

    .text{
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    h3 {
      color: $brand-gray-140;
      margin: 0;
      font-size: 15px;
      font-weight: 600;
      font-style: normal;
      line-height: 28px;
      text-transform: lowercase;
      padding-left: 10px;
    }

    h3::first-line{
      text-transform: capitalize;
    }

    p {
      color: $brand-orange;
      margin: 0;
      font-weight: 600;
      line-height: 28px;
    }
  }

  .square{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    p {
      color: aliceblue;
      text-align: center;
    }

  }

  

  .square-red{
      height: 27px;
      width: 27px;
      margin-right: 10px;
      border-radius: 7px;
     background-color: #E43D30;
      // border: 1px solid black;
      
  }
  .square-yellow{
      height: 27px;
      width: 27px;
      margin-right: 10px;
      border-radius: 7px;
     background-color: #FBC119;
      // border: 1px solid black;
  }
  // .square-green{
  //   height: 27px;
  //   width: 27px;
  //   margin-right: 10px;
  //   border-radius: 27px;
  //    background-color: #229342;
  //     border: 1px solid black;
  //     box-shadow: 0 0 5px;
  // }
}
