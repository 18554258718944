@import '../../styles/variables/variables';

.fullName-line {
  display: flex;
  border-bottom: 1px solid $brand-gray-120;
  margin: 0 30px;
  padding: 10px 0 10px;
  align-items: center;


  &-content {
    margin-left: 10px;

    h3 {
      color: $brand-gray-140;
      margin: 0;
      font-size: 15px;
      font-weight: 600;
      font-style: normal;
      line-height: 28px;
      text-transform: lowercase;
      cursor: pointer;      
    }

    h3::first-line{
      text-transform: capitalize;
    }

    p {
      color: $brand-orange;
      margin: 0;
      font-weight: 600;
      line-height: 28px;
    }
  }
}
