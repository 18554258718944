.dashboard-ui-title  {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
}
.dashboard-ui-title h2 {
    text-align: center;
    padding: 14px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 30px;
    letter-spacing: -0.9px;
    color: #1e5ab6;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 10px;
}