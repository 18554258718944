.global-image {

    & img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
    }

    &-null {
        background-color: #f1963a;
        width: 56px;
        height: 56px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        & span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 28px;
            color: #FFFFFF;
        }
    }
}